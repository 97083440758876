.card {
    display: block;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    transition: all 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }
  
  .description {
    font-size: 1rem;
    color: #666;
  }
  