.container {
    display: flex;
    flex-direction: column;
    text-align: center;
  
    img {
      max-width: 250px;
      align-self: center;
      background-color: var(--project-card-bg);
      border-radius: 20px;
    }
  
    a {
      width: fit-content;
      text-decoration: none;
      color: #222;
      transition: transform 200ms ease-in-out;
    }
  
    a:hover {
      transform: scale(1.05);
    }
  }
  
  .projectsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  @media (width >= 800px) {
    .projectsContainer {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 0 30px;
    }
  }