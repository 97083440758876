:root {
  --background-color: #fff;
  --btn-color: #0987f2;
  --btn-text-color: #fff;
  --project-card-bg: #ffffff00;
  --text-color: #222;
  --form-text-color: #555;
  --footer-text-color: #888;
}

[data-theme='dark'] {
  --background-color: #222;
  --btn-color: #fff;
  --btn-text-color: #222;
  --project-card-bg: #fff;
  --text-color: #fff;
  --form-text-color: #fff;
  --footer-text-color: #bbb;
  --experience-text-color: #fff;


}

body {
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  margin: 0 auto 60px;
}

#root {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

h1,
h2,
h3,
p {
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

h2,
h3,
p {
  font-family: 'Roboto Mono', monospace;
}


h2,
h3 {
  font-size: 20px;
}

h1 {
  font-family: 'Rubik', sans-serif;
  font-size: 32px;
}

p {
  font-size: 16px;
  font-weight: 300;
}

.sectionTitle {
  margin-bottom: 30px;
}

.hover {
  cursor: pointer;
}

@media (width >= 800px) {
  #root {
    gap: 100px;
  }
  h1 {
    font-size: 40px;
  }
  h2,
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 20px;
  }

  .sectionTitle {
    margin-bottom: 60px;
  }

  @media (width >= 1400px) {
    #root {
      gap: 200px;
    }
    h1 {
      font-size: 48px;
    }

    .sectionTitle {
      margin-bottom: 75px;
    }
  }
}