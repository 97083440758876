.container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  textarea,
  input:not([type='submit']) {
    height: 50px;
    padding-left: 16px;
    width: 250px;
  }
  
  textarea {
    padding-top: 16px;
    resize: none;
    height: 250px;
  }
  
  input,
  textarea {
    border-radius: 20px;
    border: #888 1px solid;
    box-sizing: border-box;
    justify-self: center;
    background-color: var(--background-color);
    color: var(--form-text-color);
  }
  
  ::placeholder {
    color: var(--form-text-color);
  }
  
  input[type='submit'] {
    background-color: var(--btn-color);
    color: var(--btn-text-color);
    border: none;
    border-radius: 20px;
    width: 126px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
    margin: auto;
  }
  
  input[type='submit']:hover {
    transform: scale(1.05);
  }
  
  input[type='submit']:active {
    transform: translateY(2px);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }
  
  @media (width >= 800px) {
    textarea,
    input:not([type='submit']) {
      width: 600px;
      font-size: 20px;
    }
    form {
      gap: 40px;
    }
  }
  
  @media (width >= 1400px) {
    textarea,
    input:not([type='submit']) {
      width: 800px;
    }
  }